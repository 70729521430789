import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const fragment = graphql`
  fragment BlogInterestedFragment on WordPress_Post_Blogfields {
    interestedIn {
      text
      title
    }
  }
`

const BlogInterested = ({ interest, phone, companyDescription, companyContactEmail }) => (
  <Outer>
    {interest.title && <hr />}
    <Title
      x-ms-format-detection="none"
      dangerouslySetInnerHTML={{ __html: companyContactEmail }}
    ></Title>
    <MainContent dangerouslySetInnerHTML={{ __html: companyDescription }} />
  </Outer>
)

const Outer = styled.div`
  position: relative;
  max-width: 900px;
  padding: 0 3rem 65px;
  margin: 0 auto;

  @media (max-width: 1920px) {
    max-width: 109ch;
  }

  hr {
    margin: 5rem 0;
  }
`
const Title = styled.div`
  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 3.9rem;
    letter-spacing: -0.075rem;
    margin-bottom: 3.5rem;
    width: 100%;
    display: inline-block;

    @media (max-width: 1024px) {
      font-size: 5rem;
      width: 95%;
    }

    @media (max-width: 650px) {
      margin: 3rem 0;
      line-height: 1.2;
      font-size: 4rem;
    }

    span {
      color: ${props => props.theme.colours.pink};
    }
  }
`

const MainContent = styled.div`
  @media (max-width: 1024px) {
    font-size: 2rem;
    line-height: 1.9;
  }

  @media (max-width: 650px) {
    margin: 3rem 0;
    font-size: 2.25rem;
    line-height: 1.9;
    width: 100%;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.7;

    @media (max-width: 650px) {
      font-size: 2.25rem;
      line-height: 1.9;
    }

    a {
      transition: color 0.3s ease;

      &:hover {
        color: ${props => props.theme.colours.grey};
      }
    }
  }
`

export default BlogInterested
