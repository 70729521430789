import React, { useState, useContext, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { useTrail, useSpring, animated } from 'react-spring'
import Observer from '../observer'
import { TransitionContext } from '../context/transition-context'

import AllBlog from '../../images/all.svg'
import { aspectRatio } from '../../helpers/aspect-ratio'

export const fragment = graphql`
  fragment BlogRelatedFragment on WordPress_Post {
    slug
    id
    title
    blogFilters {
      edges {
        node {
          name
        }
      }
    }
    blogFields {
      relatedPosts {
        backgroundImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        relatedBlogPosts {
          ... on WordPress_Post {
            slug
            blogFilters {
              edges {
                node {
                  name
                }
              }
            }
            blogFields {
              featuredInfos {
                title
                image {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      featuredInfos {
        image {
          sourceUrl
          mediaItemId
          modified
          altText
          imageFile {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const BlogRelated = ({ posts, currentId, background, related }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animationToggle, setAnimationToggle] = useState(false)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const titleRef = useRef()
  const contentRef = useRef()

  const relatedItemAnimation = useTrail(related.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: animationToggle ? 300 : 50
  })

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <WrapInside>
        <BackgroundImg fluid={background.backgroundImage.imageFile.childImageSharp.fluid} />
        <Observer element={titleRef.current} toggle={animationToggle} setToggle={setAnimatedTitle}>
          <Title style={titleAnimation} ref={titleRef}>
            More from the blog
          </Title>
        </Observer>
        <Observer
          element={contentRef.current}
          toggle={animationToggle}
          setToggle={setAnimationToggle}
        >
          <WrapRelated ref={contentRef}>
            {relatedItemAnimation.map(({ x, ...rest }, index) => {
              const relatedItem = related[index]
              return (
                <RelatedItem
                  key={index}
                  to={`/blog/${relatedItem.slug}`}
                  onClick={event => {
                    event.preventDefault()
                    setTransitionActive(true)
                    setTimeout(() => {
                      navigate(`/blog/${relatedItem.slug}`)
                      setTransitionActive(false)
                    }, 500)
                  }}
                  style={{
                    ...rest,
                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                  }}
                >
                  <WrapImage>
                    <Img
                      style={{ position: 'absolute' }}
                      fluid={
                        relatedItem.blogFields.featuredInfos.image.imageFile.childImageSharp.fluid
                      }
                      alt={relatedItem.blogFields.featuredInfos.image.altText}
                      // hover="true"
                    />
                  </WrapImage>
                  <p>
                    {relatedItem.blogFilters.edges.map((cat, j) => (
                      <Cat key={j}>{cat.node.name} </Cat>
                    ))}
                  </p>
                  <h2> {relatedItem.blogFields.featuredInfos.title} </h2>
                </RelatedItem>
              )
            })}
            <WrapSquareAll
              to="/blog"
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/blog`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              <AllBlog />
              <p>All blogs</p>
            </WrapSquareAll>
          </WrapRelated>
        </Observer>
      </WrapInside>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;

  @media (max-width: 1260px) {
    padding: 100px 0;
  }

  @media (max-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 650px) {
    padding: 0;
  }
`

const BackgroundImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const WrapInside = styled.div`
  position: relative;
  top: 0;
  z-index: 10;
  padding: 125px 0;

  @media (max-width: 1024px) {
    padding: 6rem 10%;
  }

  @media (max-width: 650px) {
    padding: 6rem 0;
  }
`

const Title = styled(animated.h2)`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5rem;
  letter-spacing: -0.075rem;
  margin-left: 15%;
  margin-bottom: 3.5rem;
  position: relative;
  z-index: 10;

  @media (max-width: 1024px) {
    margin: 3rem;
    font-size: 5rem;
  }
`

const WrapRelated = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 15%;
  margin-right: 2.5rem;

  @media (max-width: 1024px) {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    justify-content: center;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
`

const RelatedItem = styled(animated(Link))`
  background-color: ${props => props.theme.colours.white};
  width: calc(30% - 35px);
  margin: 0 5rem 0 0;
  padding: 0 0 3rem 0;
  min-height: 470px;

  @media (min-width: 1900px) {
    min-height: 660px;
  }

  @media (max-width: 1024px) {
    width: calc(50% - 20px);
    margin: 0 10px;
    min-height: 420px;
  }

  @media (max-width: 650px) {
    width: calc(100% - 50px);
    padding: 0 0 3rem 0;
    margin: 0 2.5rem;
    min-height: 350px;

    &:first-child {
      margin-bottom: 5rem;
    }
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }

  &:hover img {
    transform: scale(1.1);
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.5rem;
    letter-spacing: -0.075rem;
    line-height: 1.45;
    margin: 0 2.5rem;
    transition: color 0.25s ease-in-out;

    @media (max-width: 650px) {
      font-size: 2.5rem;
    }
  }

  p {
    margin: 0 2.5rem;
  }
`

const WrapSquareAll = styled(Link)`
  margin-left: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 470px;

  @media (min-width: 1900px) {
    min-height: 660px;
  }

  @media (max-width: 1024px) {
    display: block;
    margin-left: 0;
    margin-top: 5rem;
    min-height: auto;
    height: auto;
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: auto;
    height: auto;

    &:first-child {
      margin-bottom: 5rem;
    }
  }

  svg {
    @media (min-width: 1700px) {
      width: 80px;
      height: 80px;
    }
  }

  p {
    margin: 1rem 0 0 0;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    color: ${props => props.theme.colours.white};
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: 1.3rem;
    }

    @media (max-width: 650px) {
      font-size: 1.5rem;
    }
  }
`

const WrapImage = styled.div`
  margin-bottom: 2rem;
  ${aspectRatio(658, 475)}
  transition: transform 0.5s ease-in-out;

  img {
    transition: transform 0.5s !important;
  }
`

const Cat = styled.span`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  line-height: 1.75;

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 1.75rem;
    margin-right: 1.5rem;
  }
`

export default BlogRelated
