import React, { useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import VideoObserver from './video-observer'

import PlayLogo from '../images/play-video.svg'
import PauseLogo from '../images/pause.svg'
import MuteLogo from '../images/mute.svg'
import UnmuteLogo from '../images/unmute.svg'

const SwitchVideoCase = (videoType, block, loop, isBlog) => {
  const videoRef = useRef()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayingButton, setIsPlayingButton] = useState(true)
  const [isPlayingDesktop, setIsPlayingDesktop] = useState(true)
  const [isMuted, setIsMuted] = useState(true)
  const [isDesktop, setIsDesktop] = useState(null)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }

    if (isDesktop) {
      setIsPlaying(true)
      setIsPlayingButton(true)
      setIsPlayingDesktop(true)
    }

    if (!isDesktop) {
      setIsPlaying(false)
      setIsPlayingButton(false)
    }
  }, [isDesktop])

  /* eslint-disable */
    switch(videoType) {
      case 'file':
        return (
          <VideoObserver element={videoRef.current} toggle={isPlaying} setToggle={setIsPlaying}>
          <>
            <ResponsiveWrap
              ref={videoRef}
            >
            {isDesktop ?
              <ReactPlayer 
                url={block.fileLink.mediaItemUrl}
                width='100%'
                height='100%'
                playing={isPlaying && isPlayingDesktop}
                controls={false}
                muted={isMuted}
                loop={loop ? true : false}
                playsinline={true}
              />
              :
              <ReactPlayer 
                url={block.fileLink.mediaItemUrl}
                width='100%'
                height='100%'
                playing={isPlaying && isPlayingButton}
                controls={false}
                muted={isMuted}
                loop={loop ? true : false}
                playsinline={true}
              />
            }

              {(block.posterImage && !isPlayingButton) ? 
                <PosterImage
                  style={{ position: 'absolute' }}
                  fluid={block.posterImage.imageFile.childImageSharp.fluid}
                  alt="video poster image"
                />
                : 
                ""
              }
              {isDesktop ?
                <PlayButton onClick={() => {
                    setIsPlayingButton(!isPlayingButton)
                    setIsPlayingDesktop(!isPlayingDesktop)
                } }>
                  {(!isPlayingButton) ? <PauseLogo/> : <PlayLogo/> }
                </PlayButton>
              :
                <PlayButton onClick={() => {
                  setIsPlayingButton(!isPlayingButton)
                } }>
                  {(isPlayingButton) ? <PauseLogo/> : <PlayLogo/> }
                </PlayButton>
              }
              <MuteButton onClick={() => setIsMuted(!isMuted)}>
                {isMuted ? <UnmuteLogo/> : <MuteLogo/> }
              </MuteButton>
            </ResponsiveWrap>
            </>
          </VideoObserver>
        )
        break;
      case 'embed':
        return (
          <ResponsiveWrap>
            <ReactPlayer 
              url={block.embedLink}
              width='100%'
              height='100%'
              className='react-player'
            />
          </ResponsiveWrap>
        )
        break;
      default:
        return null;
    }
    /* eslint-enable */
}

const VideoElement = ({ block, videoSize, isBlog, loop }) => (
  <Outer videoSize={videoSize} isBlog={isBlog} className={`${isBlog === 'true' ? 'is-blog' : ''}`}>
    {SwitchVideoCase(block.videoType, block, loop)}
  </Outer>
)

const Outer = styled.div`
  width: 100%;
  position: relative;
  padding: ${props => (props.videoSize === 'full_width' ? '0' : '0 15%')};
  max-width: ${props => (props.isBlog === 'true' ? '900px' : '100%')};

  @media (max-width: 1920px) {
    max-width: ${props => (props.isBlog === 'true' ? '109ch' : 'auto')};
  }

  @media (max-width: 1260px) {
    padding: 0;
  }

  @media (max-width: 650px) {
    padding: 0;
  }

  &.is-blog {
    padding: 0 3rem;
  }

  .react-player__play-icon {
    display: none;
  }
`

const PosterImage = styled(Img)`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
`

const PlayButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 0;
  background-color: ${props => props.theme.colours.pink};
  color: ${props => props.theme.colours.white};
  border-radius: 15px;
  height: 25px;
  width: 35px;
  opacity: 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (min-width: 1025px) {
    opacity: 0;
    height: 30px;
    width: 40px;
    bottom: 20px;
    left: 20px;
  }

  &:hover {
    background-color: ${props => props.theme.colours.lightGrey};
    color: ${props => props.theme.colours.pink};
  }

  svg {
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MuteButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 55px;
  border: 0;
  background-color: ${props => props.theme.colours.pink};
  color: ${props => props.theme.colours.white};
  border-radius: 15px;
  height: 25px;
  width: 35px;
  opacity: 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (min-width: 1025px) {
    opacity: 0;
    height: 30px;
    width: 40px;
    left: 75px;
    bottom: 20px;
  }

  &:hover {
    background-color: ${props => props.theme.colours.lightGrey};
    color: ${props => props.theme.colours.pink};
  }

  svg {
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ResponsiveWrap = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  &:hover {
    ${PlayButton} {
      opacity: 1;
    }

    ${MuteButton} {
      opacity: 1;
    }
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default VideoElement
