import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import { aspectRatio } from '../../helpers/aspect-ratio'

export const fragment = graphql`
  fragment SingleBlogHeroFragment on WordPress_Post_Blogfields {
    featuredInfos {
      title
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const SingleBlogHero = ({ hero, date, authorName, fallbackAuthor, authorPhoto }) => {
  const OrdinalSuffixOfDay = i => {
    const j = i % 10
    const k = i % 100
    if (j === 1 && k !== 11) {
      return `${i}st`
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`
    }
    return `${i}th`
  }

  const GetMonth = m => {
    if (m === 'Jan') {
      return 'January'
    }
    if (m === 'Feb') {
      return 'February'
    }
    if (m === 'Mar') {
      return 'March'
    }
    if (m === 'Apr') {
      return 'April'
    }
    if (m === 'May') {
      return 'May'
    }
    if (m === 'Jun') {
      return 'June'
    }
    if (m === 'Jul') {
      return 'July'
    }
    if (m === 'Aug') {
      return 'August'
    }
    if (m === 'Sep') {
      return 'September'
    }
    if (m === 'Oct') {
      return 'October'
    }
    if (m === 'Nov') {
      return 'November'
    }
    if (m === 'Dec') {
      return 'December'
    }
  }

  const d = new Date(date)

  const convertDate = () => {
    const dateArr = d.toDateString().split(' ')
    return `${OrdinalSuffixOfDay(dateArr[2])} ${GetMonth(dateArr[1])} ${dateArr[3]}`
  }

  const smallInfosnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,10px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 900,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const imageAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 700,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <DateItem style={smallInfosnimation}>{convertDate()}</DateItem>
      <PostTitle style={titleAnimation}>{hero.title}</PostTitle>
      {authorName && (
        <Author style={smallInfosnimation}>
          <AuthorPhoto>
            <Img fluid={authorPhoto} />
          </AuthorPhoto>
          <AuthorName>by {authorName}</AuthorName>
        </Author>
      )}
      {!authorName && (
        <Author style={smallInfosnimation}>
          <AuthorName>by {fallbackAuthor}</AuthorName>
        </Author>
      )}
      <HeroImg style={imageAnimation} fluid={hero.image.imageFile.childImageSharp.fluid} />
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 25rem 15% 0 15%;
  overflow: hidden;

  @media (max-width: 1260px) {
    padding: 17rem 0 0;
  }

  @media (max-width: 1024px) {
    padding: 30rem 0 0 0;
  }

  @media (max-width: 650px) {
    padding: 15rem 0 0 0;
  }

  @media (max-width: 650px) and (min-height: 750px) {
    padding: 20rem 0 0 0;
  }
`

const DateItem = styled(animated.h5)`
  color: ${props => props.theme.colours.pink};
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15rem;

  @media (max-width: 650px) {
    font-size: 1.5rem;
  }
`

const PostTitle = styled(animated.h1)`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 4.8rem;
  line-height: 1.2;
  letter-spacing: -0.075rem;
  text-align: center;
  width: 80%;
  margin: 2rem auto 3.5rem auto;

  @media (max-width: 1024px) {
    font-size: 5rem;
  }

  @media (max-width: 650px) {
    font-size: 4rem;
  }
`

const Author = styled(animated.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`

const AuthorName = styled.h5`
  color: ${props => props.theme.colours.pink};
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.15rem;

  @media (max-width: 650px) {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`

const AuthorPhoto = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
`

const HeroImg = styled(animated(Img))`
  width: 100%;
  margin: 7rem auto 5rem auto;
  ${aspectRatio(1440, 770)}

  @media (max-width: 1024px) {
    margin: 15rem auto 7.5rem auto;
  }

  @media (max-width: 650px) {
    height: 300px;
  }
`

export default SingleBlogHero
