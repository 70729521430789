import React from 'react'
import styled from 'styled-components'

const Spacer = ({ size }) => <SpacerItem size={size}></SpacerItem>

const SpacerItem = styled.div`
  display: block;
  width: 100%;
  height: ${props => {
    if (props.size === 'small') {
      return '5rem'
    }
    if (props.size === 'medium') {
      return '10rem'
    }
    if (props.size === 'large') {
      return '15rem'
    }
  }};
`

export default Spacer
