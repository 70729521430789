import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SingleBlogHero from '../components/single-blog/hero'
import BlogRelated from '../components/single-blog/related'
import BlogMain from '../components/single-blog/main-content'
import BlogInterested from '../components/single-blog/interested-in'
import SEO from '../components/single-blog/seo'

const SingleBlog = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.postBy.seo} />
    <SingleBlogHero
      hero={data.wordPress.postBy.blogFields.featuredInfos}
      authorName={
        data.wordPress.postBy.blogFields.postAuthor
          ? data.wordPress.postBy.blogFields.postAuthor.title
          : null
      }
      authorPhoto={
        data.wordPress.postBy.blogFields.postAuthor
          ? data.wordPress.postBy.blogFields.postAuthor.authorFields.photo.imageFile.childImageSharp
              .fluid
          : null
      }
      fallbackAuthor={data.wordPress.postBy.author.name}
      date={data.wordPress.postBy.date}
    />
    <BlogMain mainContent={data.wordPress.postBy.blogFields.mainContent} />
    <BlogInterested
      interest={data.wordPress.postBy.blogFields.interestedIn}
      phone={data.wordPress.globalOptions.optionFields.contactInfos.phone}
      companyDescription={data.wordPress.globalOptions.optionFields.blog.companyDescription}
      companyContactEmail={data.wordPress.globalOptions.optionFields.blog.companyContactEmail}
    />
    {data.wordPress.postBy.blogFields.relatedPosts.relatedBlogPosts[0] && (
      <BlogRelated
        background={data.wordPress.postBy.blogFields.relatedPosts}
        related={data.wordPress.postBy.blogFields.relatedPosts.relatedBlogPosts}
        posts={data.wordPress.posts}
        currentId={data.wordPress.postBy.id}
      />
    )}
  </Layout>
)

export default SingleBlog

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      globalOptions {
        optionFields {
          contactInfos {
            phone
          }
          blog {
            companyContactEmail
            companyDescription
          }
        }
      }
      posts(first: 100) {
        edges {
          node {
            id
            title
            slug
            blogFilters {
              edges {
                node {
                  name
                }
              }
            }
            blogFields {
              featuredInfos {
                image {
                  sourceUrl
                  mediaItemId
                  modified
                  altText
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      postBy(postId: $wordpressId) {
        ...SeoBlogFragment
        id
        author {
          name
        }
        date
        blogFields {
          ...SingleBlogHeroFragment
          ...BlogMainContentFragment
          ...BlogInterestedFragment
          postAuthor {
            ... on WordPress_Author {
              title
              authorFields {
                photo {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 400, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          relatedPosts {
            backgroundImage {
              sourceUrl
              mediaItemId
              modified
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            relatedBlogPosts {
              ... on WordPress_Post {
                slug
                blogFilters {
                  edges {
                    node {
                      name
                    }
                  }
                }
                blogFields {
                  featuredInfos {
                    title
                    image {
                      sourceUrl
                      mediaItemId
                      modified
                      imageFile {
                        childImageSharp {
                          fluid(maxWidth: 1200, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
