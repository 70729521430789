import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import VideoElement from '../video.js'
import Spacer from '../spacer'

export const fragment = graphql`
  fragment BlogMainContentFragment on WordPress_Post_Blogfields {
    mainContent {
      ... on WordPress_Post_Blogfields_MainContent_Image {
        fieldGroupName
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      ... on WordPress_Post_Blogfields_MainContent_TextAndTitle {
        fieldGroupName
        text
      }
      ... on WordPress_Post_Blogfields_MainContent_TextAndList {
        fieldGroupName
        title
        listItem {
          text
        }
      }
      ... on WordPress_Post_Blogfields_MainContent_Spacer {
        fieldGroupName
        size
      }
      ... on WordPress_Post_Blogfields_MainContent_Video {
        fieldGroupName
        videoType
        videoSize
        embedLink
        loop
        fileLink {
          mediaItemUrl
        }
      }
      ... on WordPress_Post_Blogfields_MainContent_ImageAndText {
        fieldGroupName
        text
        order
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const SwitchBlogContent = (fieldName, block, i) => {
  /* eslint-disable */
  switch(fieldName) {
    case 'post_Blogfields_MainContent_Image':
      return (
        <ImageWrap key={i}>
        <Image key={i} fluid={block.image.imageFile.childImageSharp.fluid} />
        </ImageWrap>
      )
      break;
    case 'post_Blogfields_MainContent_TextAndTitle':
      return (
        <WrapText key={i}>
          <MainContent dangerouslySetInnerHTML={{ __html: block.text }} />
        </WrapText>
      )
      break;
    case 'post_Blogfields_MainContent_TextAndList':
      return (
        <WrapTextList key={i}>
          <Title>{block.title}</Title> 
          <MainContent>
            <ul>
            {block.listItem.map((item, i) => (
              <li key={i}>{item.text}</li>
            ))}
            </ul>
          </MainContent>
        </WrapTextList>
      )
      break;
    case 'post_Blogfields_MainContent_Video':
      return (
        <VideoElement key={i} block={block} videoSize={block.videoSize} loop={block.loop} isBlog="true" padding="none" />
      )
      break;
    case 'post_Blogfields_MainContent_ImageAndText':
      return (
        <WrapImageText key={i} className={block.order == 'text image' ? 'reverse' : ''}>

          <Image key={i} fluid={block.image.imageFile.childImageSharp.fluid} />

          <Wrap>
            <MainContent dangerouslySetInnerHTML={{ __html: block.text }} />
          </Wrap>
        </WrapImageText>
      )
      break;
    case 'post_Blogfields_MainContent_Spacer':
      return (
        <Spacer key={i} block={block} size={block.size} />
      )
      break;
    default:
      return null;
  }
  /* eslint-enable */
}

const BlogMain = ({ mainContent }) => (
  <Outer>{mainContent.map((block, i) => SwitchBlogContent(block.fieldGroupName, block, i))}</Outer>
)

const Outer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1260px) {
    padding: 0 0 65px 0%;
  }
`

const ImageWrap = styled.section`
  width: 100%;
  max-width: 109ch;
  padding: 0 3rem;
`

const Image = styled(Img)`
  width: 100%;
`

const WrapText = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 0 3rem;
`

const MainContent = styled.div`
  @media (max-width: 1920px) {
    max-width: 100ch;
    margin: auto;
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4 {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.sofia};
    letter-spacing: -0.075rem;
    margin: 3.5rem 0;

    @media (max-width: 650px) {
      margin: 3rem 0;
    }
  }

  h2 {
    font-size: 5rem;
  }

  h3 {
    font-size: 4rem;
  }

  h4 {
    font-size: 3rem;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.7;

    @media (max-width: 1024px) {
      font-size: 2rem;
      line-height: 1.9;
    }

    @media (max-width: 650px) {
      font-size: 2.25rem;
      line-height: 1.9;
    }
  }

  li {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.75;

    @media (max-width: 1024px) {
      font-size: 2rem;
      line-height: 1.9;
    }

    @media (max-width: 650px) {
      font-size: 2.25rem;
      line-height: 1.9;
    }
  }
`

const WrapTextList = styled.div`
  margin: 5rem 0;

  @media (max-width: 1260px) {
    width: 100%;
    margin: 10rem 0 0 0;
  }
`

const WrapImageText = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;

  @media (max-width: 1260px) {
    width: 100%;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  ${Image} {
    margin: 0;
    width: 45%;

    @media (max-width: 650px) {
      width: 100%;
      margin-bottom: 4rem;
    }
  }
`

const Wrap = styled.div`
  width: 45%;

  @media (max-width: 650px) {
    width: 100%;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5rem;
  letter-spacing: -0.075rem;
  margin-bottom: 3.5rem;

  @media (max-width: 650px) {
    margin: 3rem 0;
  }
`

export default BlogMain
